<template>
  <div class="contact">
    <h1 class="title">Me contacter</h1>
    <form @submit.prevent="sendEmail()" class="contact-form">
      <div class="form-row">
        <label for="fullname" class="contact-label">Nom complet</label>
        <input type="text" v-model="message.fullname" name="fullname" id="fullname" class="form-input" required>
      </div>
      <div class="form-row">
        <label for="email" class="contact-label">Email</label>
        <input type="email" v-model="message.email" name="email" id="email" class="form-input" required>
      </div>
      <div class="form-row">
        <label for="subject" class="contact-label">Objet</label>
        <input type="text" v-model="message.subject" name="subject" id="subject" class="form-input" required>
      </div>
      <div class="form-row">
        <label for="text" class="contact-label">Message</label>
        <textarea v-model="message.text" name="text" id="text" class="form-textarea" required></textarea>
      </div>
      <div class="form-btn">
        <button type="submit" class="btn">Envoyer</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: {
        fullname: '',
        email: '',
        subject: '',
        text: ''
      }
    }
  },
  methods: {
    sendEmail() {
      this.$emit('send-email', this.message)
      this.message = {
        fullname: '',
        email: '',
        subject: '',
        text: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  .contact-form {
    min-width: 100%;
    .form-row {
      flex-direction: column;
      align-items: center;
      .form-input {
        width: 90%;
      }
      .form-textarea {
        width: 90%;
        min-height: 200px;
      }
    }
  }
  .contact-label {
    margin-bottom: 5px;
  }
}

.contact {
  margin-top: 20px;
}

.contact-form {
  width: 66%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.contact-label {
  flex: 1;
  font-weight: 300;
  font-size: large;
}

.form-input {
  font-family: "Roboto", sans-serif;
  font-size: medium;
  flex: 3;
  border: 1px solid #dcdde1;
  border-radius: 2px;
  padding: 5px;
  background-color: #f8f8f8;
  &:hover {
    border-color: #093145;
    transition: 0.5s;
  }
  &:required:valid {
    border: 1px solid #dcdde1;
  }
  &:focus:required:invalid {
    border-color: red;
    box-shadow: none;
  }
  &:invalid {
    box-shadow: none;
  }
}

.form-textarea {
  font-family: "Roboto", sans-serif;
  font-size: medium;
  flex: 3;
  border: 1px solid #dcdde1;
  border-radius: 2px;
  padding: 5px;
  background-color: #f8f8f8;
  height: 220px;
  &:hover {
    border-color: #093145;
    transition: 0.5s;
  }
  &:required:valid {
    border: 1px solid #dcdde1;
  }
  &:focus:required:invalid {
    border-color: red;
    box-shadow: none;
  }
  &:invalid {
    box-shadow: none;
  }
}

.form-btn {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  .btn {
    font-family: "Roboto", sans-serif;
    font-size: medium;
    border: 1px solid #dcdde1;
    background-color: #f8f8f8;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    &:hover {
      cursor: pointer;
      border-color: #093145;
      transition: 0.5s;
    }
  }
}
</style>